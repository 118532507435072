<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        Allgemeine Geschäftsbedingungen.
      </h1>
      <hr>
      <aside class="menu">
        <p class="menu-label">
          Inhaltsverzeichnis
        </p>
        <ul class="menu-list">
          <li><a v-scroll="`data-collection`">Datensammlung</a></li>
          <li><a v-scroll="`accepting-the-terms`">Akzeptieren der Bedingungen</a></li>
          <li><a v-scroll="`privacy-and-your-personal-information`">Datenschutz und Ihre personenbezogenen Daten</a></li>
          <li><a v-scroll="`description-of-the-service`">Beschreibung des Dienstes</a></li>
          <li><a v-scroll="`account-information-from-third-party-sites`">Kontoinformationen von Websites Dritter</a></li>
          <li><a v-scroll="`pouch-promotions-and-third-party-links`">Pouch Promotions und Links von Drittanbietern</a></li>
          <li><a v-scroll="`your-registration-information`">Ihre Registrierungsinformationen</a></li>
          <li><a v-scroll="`your-use-of-the-service`">Ihre Nutzung des Dienstes</a></li>
          <li><a v-scroll="`rights-your-grant-to-us`">Rechte, die Sie uns einräumen</a></li>
          <li><a v-scroll="`pouchs-intellectual-property-rights`">Rechte an geistigem Eigentum von Pouch</a></li>
        </ul>
      </aside>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        Allgemeine Geschäftsbedingungen
      </h1>
      <hr>
    </template>

    <template slot="main">
      <div class="content">
        <h3 id="data-collection">Datensammlung</h3>
        <p>Diese Vereinbarung enthält die allgemeinen Geschäftsbedingungen für den Zugriff und die Nutzung der Websites und Softwareanwendungen, die von Global Savings Group Ltd. („Pouch“), einem Unternehmen der UK Ltd, entwickelt und vertrieben werden. Durch die Verwendung von Pouch-Produkten und -Diensten erklären Sie sich mit den Bestimmungen dieser Vereinbarung und den Datenschutzbestimmungen von Pouch einverstanden, die von Zeit zu Zeit in der Zukunft geändert werden können (siehe „Änderungen“ weiter unten).</p>

        <h3 id="accepting-the-terms">Akzeptieren der Bedingungen</h3>
        <p>Durch die Nutzung von Produkten, Informationen und Diensten, die von Pouch entwickelt wurden (zusammen der „Dienst“), erklären Sie sich mit dieser Vereinbarung einverstanden, unabhängig davon, ob Sie ein „Besucher“ sind (dh Sie durchsuchen einfach unsere Websites oder verwenden unsere Software, ohne sich zu registrieren) ) oder Sie sind ein „Mitglied“ (dh Sie haben sich registriert, um ein Konto bei Pouch zu erstellen). Der Begriff "Sie" oder "Benutzer" bezieht sich auf einen Besucher oder ein Mitglied. Der Begriff "wir" bezieht sich auf Pouch. Sie dürfen den Service nicht nutzen und diese Vereinbarung nicht akzeptieren, wenn Sie minderjährig und somit nicht unbeschränkt geschäftsfähig sind. Wenn Sie diese Vereinbarung akzeptieren, versichern Sie, dass Sie geschäftsfähig sind, beziehungsweise, dass sie vertretungsberechtigt sind, wenn Sie im Namen eines Unternehmens oder einer juristischen Person handeln.</p>

        <h3 id="privacy-and-your-personal-information">Datenschutz und Ihre personenbezogenen Daten</h3>
        <p>Informationen zu den Datenschutz- und Datenschutzbestimmungen von Pouch finden Sie in den Datenschutzbestimmungen von Pouch, die hiermit in diese Vereinbarung aufgenommen werden. In dieser Richtlinie wird erläutert, wie Pouch Ihre persönlichen Daten behandelt, wenn Sie auf den Service zugreifen. Die Richtlinie kann von Zeit zu Zeit nach unserem Ermessen aktualisiert werden. Änderungen werden mit der Veröffentlichung auf der Website wirksam.</p>

        <h3 id="description-of-the-service">Beschreibung des Dienstes</h3>
        <p>Der Service ist eine Reihe von Tools, mit denen Benutzer Angebote, Gutscheincodes, exklusiv ausgehandelte Angebote, Produkt- und andere Informationen finden und erhalten können, um fundierte Kaufentscheidungen zu attraktiven Preisen zu treffen.</p>
        <p>Der Service wird Ihnen von Pouch als kostenloser Service zur Verfügung gestellt. Wir arbeiten für Sie daran, die besten öffentlich verfügbaren Gutscheincodes zu finden und exklusive Angebote auszuhandeln, die besser sind als jedes öffentlich verfügbare Angebot. Diese Angebote werden in der Pouch-Software, über Pouch-Werbeaktionen vor Ort, per E-Mail oder auf unseren verschiedenen Social-Media-Plattformen kommuniziert. Wir verdienen Geld durch Provisionszahlungen und Gebühren von Einzelhändlern für einen Teil der Verkäufe, zu deren Erzielung wir unsere Benutzer anregen.</p>

        <h3 id="account-information-from-third-party-sites">Kontoinformationen von Websites Dritter</h3>
        <p>Im Rahmen unseres Service können Mitglieder Pouch anweisen, mit Konten zu interagieren, die sie bei Drittanbietern („Drittanbieter-Konten“) unterhalten, wie zum Beispiel Facebook, Twitter oder E-Mail. Pouch überprüft nicht die Drittanbieter-Konten hinsichtlich eines Datenabgleichs oder der Einhaltung gesetzlicher Bestimmungen bzw. von Rechtsverletzungen. Pouch ist nicht verantwortlich für die Produkte und Dienstleistungen, die von oder auf Websites Dritter angeboten werden. Wenn Ihre Berechtigungseinstellungen dies zulassen, kann Pouch Informationen von Ihren Drittanbieter-Accounts importieren, um Ihnen die Dienste besser anbieten zu können. Pouch kontrolliert nicht die Richtlinien und Praktiken von Websites oder Diensten Dritter, einschließlich Drittanbieter-Konten, die Sie mit den Diensten verbinden.</p>
        <p>Pouch kann technische oder andere Schwierigkeiten nicht immer vorhersehen oder antizipieren, die dazu führen können, dass keine Daten abgerufen werden oder Daten verloren gehen, Einstellungen für die Personalisierung vorgenommen werden oder andere Dienstunterbrechungen auftreten. Pouch übernimmt keine Verantwortung für die Aktualität, Richtigkeit, Löschung, Nichtzustellung oder das Versäumnis, Benutzerdaten, Mitteilungen oder Personalisierungseinstellungen zu speichern.</p>

        <h3 id="pouch-promotions-and-third-party-links">Pouch Promotions und Links von Drittanbietern</h3>
        <p>Einige Teile des Service werden durch gesponserte Links von Werbetreibenden und die Anzeige von Pouch-Werbeaktionen unterstützt, die basierend auf im Service gespeicherten Informationen, über den Service gemachten Abfragen oder anderen Informationen benutzerdefiniert auf Sie abgestimmt werden können. Wir verwenden manchmal speziell formatierte Links, für die wir eine Provision für die daraus resultierenden Verkäufe oder Klicks von Partnern erhalten („Affiliate-Links“). Wenn Sie in der Software auf ein Angebot klicken und Affiliate-Link aktiviert ist, wird automatisch ein neuer Tab geöffnet und anschließend automatisch gelöscht. Diese Aktion führt dazu, dass ein Cookie gelöscht wird.</p>
        <p>Im Zusammenhang mit Pouch-Werbeaktionen stellt der Service Links zu Websites bereit, die Pouch-Werbetreibenden und anderen Dritten gehören. Pouch unterstützt, garantiert oder garantiert nicht die Produkte oder Dienstleistungen, die durch die Pouch-Werbeaktionen (oder sonstige auf unserer Website beworbene oder verlinkte Produkte oder Dienstleistungen von Drittanbietern) erhältlich sind, unabhängig davon, ob sie gesponsert werden oder nicht, und Pouch ist kein Agent oder Makler oder anderweitig verantwortlich für die Aktivitäten oder Richtlinien dieser Websites. Pouch garantiert nicht, dass die Produktdetails, Preise, Verfügbarkeit von Gutscheincodes oder andere Servicebedingungen, Preise oder Prämien, die von einem bestimmten Werbetreibenden oder einem Dritten in unserem Service angeboten werden, tatsächlich die Bedingungen sind, die Ihnen angeboten werden können, wenn Sie das Angebot verfolgen oder dass es sich um die besten Preise, Konditionen oder niedrigsten Preise auf dem Markt handelt. Wenn Sie auf Websites von Drittanbietern zugreifen oder Dienste von Drittanbietern nutzen, akzeptieren Sie, dass dies mit Risiken verbunden ist und Pouch nicht für solche Risiken verantwortlich ist. Wir empfehlen Ihnen, sich dieser Risiken bewusst zu sein, wenn Sie die Dienste verlassen, und die Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien aller Websites oder Dienste Dritter zu lesen, die Sie besuchen oder nutzen.</p>
        <p>Pouch hat keine Kontrolle über den Inhalt, die Richtigkeit, die Datenschutzrichtlinien oder die Praktiken oder Meinungen, die auf Websites Dritter oder von Dritten, mit denen Sie über die Dienste interagieren, zum Ausdruck gebracht werden, und übernimmt keine Verantwortung dafür. Darüber hinaus wird Pouch den Inhalt von Websites oder Diensten Dritter nicht überwachen, überprüfen, zensieren oder bearbeiten. Durch die Nutzung der Dienste befreien Sie uns von jeglicher Haftung, die sich aus der Nutzung von Websites oder Diensten Dritter ergibt.</p>
        <p>Wenn es zu Streitigkeiten zwischen Pouch-Mitgliedern oder zwischen Benutzern oder zwischen Benutzern und Dritten kommt, stimmen Sie zu, dass Pouch nicht verpflichtet ist, sich daran zu beteiligen. Im Falle eines Streits mit einem oder mehreren anderen Nutzern entbinden Sie Pouch, seine leitenden Angestellten, Mitarbeiter, Vertreter und Nachfolger von Ansprüchen, Forderungen und Schäden jeglicher Art oder Art, die bekannt oder unbekannt, vermutet oder nicht vermutet, offengelegt werden oder nicht bekannt gegeben, aufgrund oder in irgendeiner Weise im Zusammenhang mit solchen Streitigkeiten und / oder unseren Dienstleistungen.</p>

        <h3 id="your-registration-information">Ihre Registrierungsinformationen</h3>
        <p>Sie stimmen zu und verstehen, dass Sie für die Wahrung der Vertraulichkeit Ihres Passworts verantwortlich sind, das Ihnen zusammen mit Ihrer Login-ID-E-Mail-Adresse den Zugriff auf den Service ermöglicht. Diese Login-ID und das Passwort zusammen mit allen anderen Kontaktinformationen, die Sie in Ihren "Registrierungsinformationen" angeben.</p>
        <p>Wenn Sie uns Ihre E-Mail-Adresse mitteilen, stimmen Sie zu, alle erforderlichen Mitteilungen elektronisch an diese E-Mail-Adresse zu senden. Es liegt in Ihrer Verantwortung, diese Adresse entsprechend zu aktualisieren oder zu ändern. Mitteilungen werden in HTML (oder, falls Ihr System HTML nicht unterstützt, in Klartext) im Text der E-Mail oder über einen Link zu der entsprechenden Seite auf unserer Website bereitgestellt, die über alle gängigen modernen, im Handel erhältlichen Standards zugänglich ist Internet-Browser.</p>
        <p>Wenn Sie Kenntnis von einer unbefugten Verwendung Ihrer Registrierungsinformationen erhalten, stimmen Sie zu, Pouch unverzüglich zu benachrichtigen.</p>

        <h3 id="your-use-of-the-service">Ihre Nutzung des Dienstes</h3>
        <p>Ihr Recht, auf den Service zuzugreifen und ihn zu nutzen, ist für Sie persönlich und kann von Ihnen nicht auf eine andere Person oder Organisation übertragen werden. Sie sind nur berechtigt, auf unseren Service für rechtmäßige, interne und nichtkommerzielle Zwecke zuzugreifen und diesen zu nutzen.</p>
        <p>Durch genaue Aufzeichnungen kann Pouch den Service für Sie bereitstellen. Damit der Dienst effektiv funktioniert, müssen Sie auch Ihre Registrierungsinformationen auf dem neuesten Stand und korrekt halten. Wenn Sie dies nicht tun, wird die Genauigkeit und Effektivität des Dienstes für Sie beeinträchtigt.</p>
        <p>Ihr Zugriff und Ihre Nutzung des Dienstes können von Zeit zu Zeit aus verschiedenen Gründen unterbrochen werden, einschließlich und ohne Beschränkung auf die Funktionsstörung von Geräten, die regelmäßige Aktualisierung, Wartung oder Reparatur des Dienstes oder anderer Aktionen, die Pouch allein durchführt Diskretion kann wählen, zu nehmen.</p>
        <p>Sie erklären sich damit einverstanden, dass Pouch Ihr Feedback, Ihre Vorschläge oder Ideen in irgendeiner Weise verwendet, einschließlich zukünftiger Änderungen des Service, anderer Produkte oder Services, Werbe- oder Marketingmaterialien. Sie gewähren Pouch eine unbefristete, weltweite, vollständig übertragbare, unterlizenzierbare, unwiderrufliche, vollständig bezahlte, lizenzgebührenfreie Lizenz, um das Feedback, das Sie Pouch geben, in irgendeiner Weise zu nutzen.</p>
        <p>Als Teil der Pouch-Community erklären Sie sich damit einverstanden und ermächtigen Pouch, Informationen über die von Ihnen besuchten Websites zu sammeln, um eine bessere Benutzererfahrung für Sie und andere Pouch-Benutzer zu erzielen. Beispiele für diese Art von Informationen sind Informationen zu den Produkten, die Ihr Browser besucht, einschließlich der aktuellen Produktpreise und anderer Produktdetails, die verwendet werden, um für Sie und andere Benutzer eine bessere Benutzererfahrung zu erzielen. In Zukunft bieten wir möglicherweise Personalisierungsoptionen für unsere Produkte an, bei denen Sie wissen, an welchen Produkten Sie interessiert sind, um relevante Inhalte und Produktangebote entweder vor Ort oder per E-Mail zu präsentieren. Während wir nach Möglichkeiten suchen, Daten für leistungsfähigere Produkterfahrungen zu verwenden, bieten wir möglicherweise Möglichkeiten, diese Datenerfassung zu deaktivieren.</p>
        <p>Sie erklären, garantieren und stimmen zu, dass Sie keine Inhalte beitragen oder die Dienste auf andere Weise nutzen oder mit den Diensten auf eine Weise interagieren, die:</p>
        <ul>
          <li>das geistige Eigentum oder sonstige Rechte Dritter (einschließlich Pouch) verletzt oder verletzt;</li>
          <li>gegen Gesetze oder Vorschriften verstößt;</li>
          <li>schädlich, betrügerisch, täuschend, bedrohlich, belästigend, diffamierend, obszön oder auf andere Weise zu beanstanden ist ;</li>
          <li>die Sicherheit Ihres Pouch-Kontos oder des Kontos anderer Personen gefährdet (z. B. das Zulassen, dass sich eine andere Person als Sie bei den Diensten anmeldet);</li>
          <li>versucht auf irgendeine Weise, das Passwort, das Konto oder andere Sicherheitsinformationen von einem anderen Benutzer zu erhalten;</li>
          <li>die Sicherheit eines Computernetzwerks verletzt oder Passwörter oder Sicherheitsverschlüsselungscodes knackt;</li>
          <li>Maillisten, Listserve, jede Form von Auto-Responder oder "Spam" verwenden, sowie alle Prozesse, die ausgeführt oder aktiviert werden, während Sie nicht bei den Diensten angemeldet sind oder die auf andere Weise die ordnungsgemäße Funktionsweise der Dienste beeinträchtigen oder eine unzumutbare Belastung für die Infrastruktur der Dienste darstellen);</li>
          <li>mittels “Crawler”, “Scraper” oder “Spider” ganze Seiten, Daten oder Teile davon die im Zusammenhang mit den Diensten oder Inhalten stehen (mithilfe manueller oder automatisierter Mittel) abgreift;</li>
          <li>einen wesentlichen Teil des Inhalts speichert;</li>
          <li>das Dekompilieren, Rückentwickeln oder sonstige Versuche, den Quellcode oder die zugrunde liegenden Ideen oder Informationen zu den Diensten zu erhalten.</li>
        </ul>
        <p>Ein Verstoß gegen die vorstehenden Bestimmungen ist ein Grund für die Kündigung Ihres Rechts zur Nutzung oder zum Zugriff auf die Dienste.</p>

        <h3 id="rights-your-grant-to-us">Rechte, die Sie uns einräumen</h3>
        <p>Durch das Übermitteln von Informationen, Daten, Passwörtern, Benutzernamen, PINs, anderen Anmeldeinformationen, Materialien und anderen Inhalten an Pouch über den Dienst lizenzieren Sie diesen Inhalt an Pouch ausschließlich zum Zweck der Bereitstellung des Dienstes. Pouch darf den Inhalt verwenden und speichern, aber nur, um Ihnen den Service bereitzustellen. Indem Sie diesen Inhalt an Pouch senden, versichern Sie, dass Sie berechtigt sind, ihn für diesen Zweck an Pouch zu senden, ohne dass Pouch zur Zahlung von Gebühren oder anderen Beschränkungen verpflichtet ist.</p>

        <h3 id="pouchs-intellectual-property-rights">Rechte an geistigem Eigentum von Pouch</h3>
        <p>Der Inhalt des Dienstes, einschließlich seines "Look and Feels" (z. B. Text, Grafiken, Bilder, Logos und Schaltflächensymbole), Fotos, redaktioneller Inhalte, Hinweise, Software (einschließlich HTML-basierter Computerprogramme) und anderer Materialien, ist geschützt sowohl im Vereinigten Königreich als auch in anderen anwendbaren Urheber-, Marken- und anderen Gesetzen. Die Inhalte des Dienstes gehören Pouch oder seinen Software- oder Inhaltsanbietern oder sind an diese lizenziert. Pouch gewährt Ihnen das Recht, den Service gemäß diesen Bedingungen anzuzeigen und zu nutzen. Sie dürfen eine Kopie der im Service bereitgestellten Informationen nur für Ihren persönlichen, internen und nicht kommerziellen Gebrauch herunterladen oder ausdrucken. Jegliche Weitergabe, Nachdruck oder elektronische Vervielfältigung von Inhalten des Dienstes, auch auszugsweise, zu anderen Zwecken ist ohne unsere vorherige schriftliche Zustimmung ausdrücklich untersagt.</p>
      </div>
    </template>
  </page-layout>
</template>

<script>
export default {
  //
}
</script>
