<template>
  <p-modal :active="active" @close="$emit('close')">
    <div class="proposition-modal has-padding-4 content">
      <p @click="part = 'never_overpay'" class="title is-5 part" id="never-overpay">
        Nie mehr zuviel bezahlen
      </p>
      <div v-show="part === 'never_overpay'">
        <p>Unsere Mission ist es, Dir beim Geld sparen zu helfen und immer den besten Preis zu bekommen. Wir tun das, indem wir die besten Gutscheincodes und Angebote suchen und anwenden, wenn Du an der Kasse deines lieblings Online-Händlers bist. Das bedeutet, dass Du den Checkout nicht mehr verlassen musst, um selbst nach Gutscheinen und Angeboten für die 1.500 (und es werden immer mehr!) Händler zu suchen, mit denen wir zusammenarbeiten.</p>
        <p>Wir stellen sicher, dass Du immer das beste Angebot bekommst, denn Pouch ist eine Browser-Erweiterung. Diese kannst Du im Chrome Web Store und in den Firefox Browser Add Ons herunterladen. Nach der Installation siehst Du das Pouch-Symbol in der Symbolleiste deines Browsers in der Nähe des oberen Bildschirmbereichs.</p>
        <p>Wenn Du einen der über 1.500 Einzelhändler in Deutschland besuchst, bei denen wir exklusive Angebote für Dich ausgehandelt haben, leuchtet das Pouch-Symbol auf. Wenn Du darauf klickst, erfährst Du, wie viele Angebote und Gutscheine bei diesem Händler erhältlich sind. Dann kannst Du die Gutscheine an der Kasse anwenden. Bei einigen Händlern wenden wir den Code automatisch an, sodass Du immer das beste Angebot erhältst, ohne den Checkout verlassen zu müssen (Win Win!).</p>
      </div>

      <hr>

      <p @click="part = 'exclusive_deals'" class="title is-5 part" id="exclusive-deals">
        Exklusive Angebote
      </p>
      <p v-show="part === 'exclusive_deals'">Wir arbeiten mit den besten Einzelhändlern Deutschlands zusammen, um Dir exklusive Angebote und Aktionen zu unterbreiten und benachrichtigen Dich, sobald Du die Website der Einzelhändler besuchst. Wir versuchen immer, die besten Angebote auf dem Markt auszuhandeln, damit Du sicher sein kannst, dass Du den bestmöglichen Preis bekommst. Wenn Du bei einem Einzelhändler einkaufst, bei dem wir kein Angebot haben, kannst Du uns dies mitteilen, indem Du uns per E-Mail oder Facebook kontaktierst.</p>

      <hr>

      <p @click="part = 'its_free'" class="title is-5 part" id="its-free">
        Pouch ist kostenlos
      </p>
      <p v-show="part === 'its_free'">Pouch ist eine Browser-Erweiterung, die Du über Chrome oder Firefox herunterlädst und anschließend als Icon in der Browser-Toolbar am oberen Bildschirmrand siehst. Wenn Du bei Händlern einkaufst, bei denen wir exklusive Angebote für Dich ausgehandelt haben, leuchtet das Symbol auf und zeigt dir an, wieviele Gutscheine und/oder Angebote wir bei diesem Händler zur Verfügung haben. Dann kannst Du die Angebote anwenden, sobald Du an der Kasse bist, und bei einigen Händlern können wir dies sogar automatisch für Dich tun. Das bedeutet, dass Du immer das beste Angebot bekommst, ohne jemals den Checkout verlassen zu müssen. Und all das ist kostenlos, Du bezahlst Pouch nichts!</p>

      <hr>

      <p @click="part = 'data_protection'" class="title is-5 part" id="data-protection">
        Unsere Datenschutzrichtlinie
      </p>
      <div v-show="part === 'data_protection'">
        <p>Wir bei Pouch nehmen den Datenschutz sehr ernst. Wir haben hier sogar einen eigenen <a href="https://joinpouch.com/blog/how-pouch-looks-after-your-data" target="_blank">Blog-Artikel</a> zu diesem Thema verfasst:</p>
        <p>Zusammenfassend kann man sagen, dass wir keine persönlichen Daten erfassen und alle Daten anonymisiert sind (im Grunde können wir nicht wissen, wer Du bist oder was Du online machst). Wenn Du Pouch installierst, siehst Du eine Nachricht von Chrome und Firefox, die Dich über die Berechtigungen informiert, auf die wir zugreifen müssen. Das ist notwendig, damit wir an den richtigen Stellen auftauchen können, um Dich über unsere exklusiven Angebote zu informieren. Diese Nachrichten von Chrome und Firefox können zwar beängstigend erscheinen, aber wir verfolgen lediglich welche E-commerce-Seiten Du besuchst, damit wir Dir relevante Angebote anzeigen können.</p>
        <p>Außerdem verkaufen wir deine Daten nicht und sind vollständig DSGVO-konform. Bevor wir von einer deutschen Firma übernommen wurden (Deutschland ist Spitzenreiter in Sachen Datenschutz!), haben wir ein eine strenge Prüfung gemeinsam mit unserem Datenschutzbeauftragten durchlaufen, welche sicherstellt, dass unsere Datenschutzbestimmungen DSGVO konform sind.</p>
        <p>Für unsere vollständige Datenschutzerklärung klicke bitte <a href="https://joinpouch.com/privacy-policy" target="_blank">hier</a>.</p>
      </div>

      <br>

      <p class="has-text-right">
        <add-to-browser
          bold
          v-only-extension-fit
          @click="$emit('close')"
          position="PropositionModalDE"
        >
          <span v-t="`buttons.add-pouch`"></span>
        </add-to-browser>
      </p>
    </div>
  </p-modal>
</template>

<script>
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  props: ['active', 'part'],

  components: { AddToBrowser },

  data () {
    return {
      activePart: this.part
    }
  },

  watch: {
    part (value) {
      this.activePart = value
    }
  }
}
</script>
