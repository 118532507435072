<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        Imprint.
      </h1>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        Imprint.
      </h1>
    </template>

    <template slot="main">
      <div class="content">
        <p>joinpouch.com is operated by:</p>
        <p>Global Savings Group Ltd,
           1 Bartholomew Lane,
           London, England,
           EC2N 2AX
        </p>

        <span class="has-text-weight-semibold">Contact:</span>
        <p>Tel: +44 7828613913</p>
        <p>Email: contact-uk@global-savings-group.com</p>
        <p>Website: https://global-savings-group.com</p>

        <span class="has-text-weight-semibold">Board of Managing Directors:</span>
        <p>Marc Vallverdú</p>
        <p>Eduard Pecker</p>

        <span class="has-text-weight-semibold">Commercial register and number:</span>
        <p>Global Savings Group Ltd,
           1 Bartholomew Lane,
           London, England,
           EC2N 2AX
        </p>
        <p>Company no: 10851586</p>
        <p>VAT no: GB280876372</p>
        <p>The European Commission provides a platform for online dispute resolution (OS): https://ec.europa.eu/consumers/odr/main/index
            Global Savings Group Ltd is not obliged to participate in dispute resolution proceedings before a consumer arbitration board.
        </p>

        <span class="has-text-weight-semibold">Data Protection</span>
        <p>Data are processed and collected in the context of the use of this website by:
           Global Savings Group Ltd,
           1 Bartholomew Lane,
           London, England,
           EC2N 2AX
        </p>
        <p>Email: privacy.pouch@joingsg.com</p>
        <p>For queries or more information, please contact our Data Protection Officer, Mr. Michael Mayer, using the contact information above. Detailed information on the processing and use of personal data in connection with the use of this website and your related rights can be found in our Privacy Policy.</p>

        <div class="has-text-weight-semibold">Liability</div><br>

        <h6>1. Liability for content:</h6>
        <p>As a service provider we are responsible for the content that appears on this website; however, we are not obliged to monitor transmitted or stored external information or to investigate circumstances that indicate illegal activity. </p>
        <p>Obligations to remove or block the use of information under general law remain unaffected. Any liability in this regard is, however, only incurred from the moment in time that knowledge of the specific breach of law is obtained. Upon notification of any violation or infringement, the content will be removed immediately. </p>
        <h6>2. Liability for links:</h6>
        <p>Our offering contains links to external third-party websites on whose content we have no influence or control. As such, we cannot assume liability for any external content. The owner or operator of the external sites linked within remains responsible for all content on those pages, which were verified at the time the link was created.</p>
        <p>Linked sites were checked for possible legal violations at the time of linking and no discernable violations were present, however permeant checking of the contents of linked sites is unreasonable without evidence of infringement. Upon notification of any violations, we will remove the corresponding links immediately.</p>
        <h6>3. Copyright:</h6>
        <p>The entirety of content created for our website – unless expressly indicated otherwise – is our property and protected under copyright law. This reproduction, processing, dissemination and any form of utilisation beyond the limits of copyright law require express written approval of Global Savings Group Ltd. Downloads and copies of our site are for private, non-commercial use only.</p>
        <p>For any content on this site not created by the operator, the copyrights of third parties are to be respected. If a violation of copyright becomes known, we ask to be informed accordingly. Content will immediately be removed upon discovery of any corresponding infringement. </p>
        <h6>4. Legal validity of this disclaimer:</h6>
        <p>This disclaimer is to be regarded as part of the internet publication from which this page was referenced. If sections or individual terms of this statement are not legal or correct, the content or validity of the other sections remains uninfluenced by this fact.</p>

      </div>
    </template>
  </page-layout>
</template>

<script>
export default {
  //
}
</script>
