<template>
  <page-layout>
      <template slot="side">
        <h1 class="title is-2 is-spaced has-text-weight-black">
          <span class="has-text-secondary">T</span>erms &<br>
          <span class="has-text-secondary">C</span>onditions.<br>
        </h1>
        <hr>
        <aside class="menu">
          <p class="menu-label">TABLE OF CONTENTS.</p>
          <div v-for="(term, key) in terms" :key="key">
            <ul medium v-if="$route.params.category === term.categorySlug" class="menu-list">
                <li
                  v-for="(question, key) in term.questions"
                  :key="key"
                >
                  <a v-scroll="`${question.questionSlug}`">{{ question.question }}</a>
                </li>
            </ul>
          </div>
        </aside>

      </template>

      <template slot="mobile">
        <h1 class="title is-2 is-spaced has-text-weight-black">
          <span class="has-text-secondary">T</span>erms &<br>
          <span class="has-text-secondary">C</span>onditions.<br>
        </h1>
        <hr>
      </template>

      <template slot="main">
        <p-tabs>
          <p-tab-item
            v-for="(term, key) in terms"
            :key="key"
            :active="$route.params.category === term.categorySlug"
            @selected="track(term.categorySlug)"
          >
            <router-link :to="`/terms-and-conditions/${term.categorySlug}`">{{term.categoryName}}</router-link>
          </p-tab-item>
        </p-tabs>

        <div v-for="(term, key) in terms" :key="key">

          <div medium class="terms-background" v-if="$route.params.category === term.categorySlug">
            <div
              class="has-space-between"
              v-for="(question, key) in term.questions"
              :key="key"
            >
              <h3 class="has-text-weight-semibold main-question" :id="`${question.questionSlug}`">{{ question.question }}</h3>
              <div class="has-text-grey">
                <p class="content main-answer" v-html="question.answer"></p>
              </div>
            </div>
          </div>
        </div>
      </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    terms: state => state.tos
  }),
  mounted () {
    // if there is no such category redirect to the first one available
    if (!this.terms.find(faq => faq.categorySlug === this.$route.params.category)) {
      this.$router.push(`/terms-and-conditions/${this.terms[0].categorySlug}`)
    }
  },
  updated () {
    // if user wants to exit the page after multiple tab clicks
    if (!this.terms.find(faq => faq.categorySlug === this.$route.params.category)) {
      this.$router.push(`/terms-and-conditions/${this.terms[0].categorySlug}`)
    }
  },
  methods: {
    scrollToElement (selector) {
      const el = this.$el.querySelector(selector)
      if (el) {
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      }
    },
    track (item) {
      console.log(item)
    }
  }
}
</script>

<style lang="sass" scoped>
  .title
    line-height: 135%
  .table-content
    font-weight: 400
    font-size: 16px
    line-height: 150%
    color: #7A7A7A
    margin-bottom: 10px
  .terms-background
    background: #FFF
    padding: 20px
  .main-question
    font-size: 24px
    color: #363636
    padding-bottom: 15px

  .main-answer
    font-size: 16px
    color: #575757

  .has-space-between
    margin-bottom: 20px

  @media screen and ( max-width: 768px )
    .p-list
      border-radius: 0
      margin-left: -1.5rem
      margin-right: -1.5rem

</style>
