<template>
  <component :is="localizedComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
export default {
  computed: {
    localizedComponent () {
      return require(`@/assets/lang/views/${this.$options.name}.${this.$i18n.locale}`).default
    }
  }
}
</script>
