<template>
  <page-layout>
    <template slot="side">
      <FaqIntro></FaqIntro>
    </template>

    <template slot="mobile">
      <FaqIntro></FaqIntro>
    </template>

    <template slot="main">
      <p-tabs>
        <p-tab-item
          v-for="(faq, key) in faqs"
          :key="key"
          :active="$route.params.category === faq.categorySlug"
          @selected="track(faq.categorySlug)"
        >
          <router-link :to="`/faq/${faq.categorySlug}`">{{faq.categoryName}}</router-link>
        </p-tab-item>
      </p-tabs>

      <div v-for="(faq, key) in faqs" :key="key">
        <p-list medium class="p-list" v-if="$route.params.category === faq.categorySlug">
          <p-list-item
            collapsible
            v-for="(question, key) in faq.questions"
            :key="key"
            :start-active="$route.params.question === question.questionSlug"
            :id="`faq${question.questionSlug}`"
            @change="track(question.questionSlug)"
          >
            <span class="has-text-weight-semibold has-text-grey" >{{ question.question }}</span>
            <div class="collapsed has-text-grey">
              <div class="content" v-html="question.answer"></div>
            </div>
          </p-list-item>
        </p-list>
      </div>
    </template>

  </page-layout>
</template>

<script>
import { mapState } from 'vuex'
import FaqIntro from '../../../views/components/FaqIntro.vue'

export default {
  components: { FaqIntro },
  computed: mapState({
    faqs: state => state.faq
  }),
  mounted () {
    // if there is no such category redirect to the first one available
    if (!this.faqs.find(faq => faq.categorySlug === this.$route.params.category)) {
      this.$router.push(`/faq/${this.faqs[0].categorySlug}`)
    }

    // wait till DOM renders and scroll to a specific question from url (if any)
    setTimeout(() => this.scrollToElement(`#faq${this.$route.params.question}`), 500)
  },
  updated () {
    // adding categoryslug if in case there's none when going back
    if (!this.$route.params.category) {
      this.$router.push(`/faq/${this.faqs[0].categorySlug}`)
    }
  },
  methods: {
    scrollToElement (selector) {
      const el = this.$el.querySelector(selector)
      if (el) {
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      }
    },
    track (item) {
      console.log(item)
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  line-height: 135%
.subtitle
  font-weight: 500
  font-size: 20px
  line-height: 150%
  color: #7A7A7A

@media screen and ( max-width: 768px )
  .p-list
    border-radius: 0
    margin-left: -1.5rem
    margin-right: -1.5rem

</style>
