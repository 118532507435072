<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        Impressum.
      </h1>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        Impressum.
      </h1>
    </template>

    <template slot="main">
      <div class="content">
        <span class="has-text-weight-semibold">Hinweis gemäß § 5 Telemediengesetz (TMG):</span>
        <p>Joinpouch.de wird betrieben von:</p>
        <p>CupoNation GmbH
          Radlkoferstr. 2
          81373 München
          Deutschland
        </p>

        <span class="has-text-weight-semibold">Kontakt:</span>
        <p>Tel: +49 (0)89 381535840</p>
        <p>Email: kontakt@cuponation.de</p>
        <p>Website: www.cuponation.de</p>

        <span class="has-text-weight-semibold">Geschäftsführer:</span>
        <p>Herr Dr. Andreas Fruth</p>
        <p>Herr Dr. Adrian Renner</p>
        <p>Herr Dr. Gerhard Trautmann</p>

        <span class="has-text-weight-semibold">Registereintrag:</span>
        <p>Registergericht: Amtsgericht München</p>
        <p>Registernummer: HTB 209 997</p>

        <span class="has-text-weight-semibold">Umsatzsteueridentifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</span>
        <p>DE 284809750</p>
        <p>Die Europäische Kommission bietet eine Plattform zur Online Streitbeilegung: https://ec.europa.eu/consumers/odr/main/index. Wir sind nicht bereit oder verpflichtet an Verbraucher Verfahren zur Streitbeilegung teilzunehmen.</p>

        <span class="has-text-weight-semibold">Hinweise zum Datenschutz:</span>
        <p>Verantwortlich für die Verarbeitung personenbezogener Daten im Rahmen der Nutzung dieser Webseite ist die:
            CupoNation GmbH
            Radlkoferstr. 2
            81373 München
        </p>
        <p>Telefon: + 49 (0)89 381535840</p>
        <p>Email: datenschutz@cuponation.de</p>
        <p>Für Anfragen und mehr Informationen kontaktieren sie bitte unseren Datenschutzbeauftragten, Herr Michael Mayer, unter dem oben angegebenem Kontakt. Detaillierte Informationen zur Erhebung und Verwendung ihrer Daten finden sie unserer Privacy Policy.</p>

        <div class="has-text-weight-semibold">Haftungsausschluss:</div><br>

        <h6>1. Haftung für Inhalte:</h6>
        <p>Als Dienstleistungsanbieter sind wir für die Inhalte auf unserer Webseite verantwortlich. Wir sind aber nicht verpflichtet extern übertragene Informationen zu überwachen oder hinsichtlich rechtswidriger Inhalte zu untersuchen.</p>
        <p>Dies betrifft nicht unsere allgemeinen gesetzlichen Verpflichtungen Informationen zu löschen oder zu blockieren. Eine Haftung hinsichtlich solcher Inhalte besteht nur ab dem Moment, in dem wir von spezifischen Gesetzesverstößen Kenntnis erhalten. Wir uns ein solcher Gesetzesverstoß mitgeteilt, wird der fragliche Inhalt umgehend entfernt.</p>
        <h6>2. Haftung für Links:</h6>
        <p>Unser Angebot enthält Links zu Webseiten von Drittanbietern über deren Inhalt wir keinen Einfluss und keine Kontrolle haben. Deswegen können wir keine Verantwortung für externe Inhalte übernehmen. Der Inhaber oder Betreiber der Drittanbieter-Webseiten ist für deren Inhalt verantwortlich.</p>
        <p>Verlinkte Webseiten sind zum Zeitpunkt der Verlinkung überprüft worden und enthielten keine erkennbaren Gesetzesverstöße, jedoch ist es unzumutbar diese Webseiten ohne erkennbaren Anlass fortlaufend zu kontrollieren. Wir werden jedoch nach Mitteilung einer Rechtsverletzung den korrespondierenden Link unverzüglich entfernen.</p>
        <h6>3. Urheberrecht:</h6>
        <p>Der Gesamte Inhalt unserer Webseite ist - soweit nicht anders angezeigt - ist unser Eigentum und unterliegt unserem Urheberrecht. Jede Reproduktion, Verarbeitung oder Verbreitung ausserhalb den Grenzen des Urheberrechts bedarf der ausdrücklichen schriftlichen Einwilligung von Global Savings Group Ltd. Der Download und die Kopie unserer Webseite darf nur zum privaten nicht-kommerziellen Zweck verwendet werden.</p>
        <p>Hinsichtlich jeglichem Inhalt auf der Webseite der nicht vom Betreiber stammt sind dessen Urheberrechte zu respektieren. Sollten Sie Kenntnis von einem Urheberrechtsverstoß erhalten, bitten wir darum unverzüglich informiert zu werden. Sollten wir eine Verletzung entdecken werden den fraglichen Inhalt unverzüglich entfernen.</p>
        <h6>4. Rechtliche Verbindlichkeit dieses Disclaimers:</h6>
        <p>Diese Erklärung ist als Teil der Internet Publikation zu betrachten, von der auf diese Seite geleitet worden ist. Sollten ein Teil oder einzelne Bestimmungen dieser Erklärung nicht den Gesetzen entsprechen oder inhaltlich falsch sein, hat dies keine Auswirkung auf die Gültigkeit des restlichen Inhalts.</p>

      </div>
    </template>
  </page-layout>
</template>

<script>
export default {
  //
}
</script>
