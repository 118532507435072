<template>
  <p-modal :active="active" @close="$emit('close')">
    <div class="proposition-modal has-padding-4 content">
      <p @click="part = 'never_overpay'" class="title is-5 part" id="never-overpay">
        Never Overpay
      </p>
      <div v-show="part === 'never_overpay'">
        <p>Our mission is to help you save money and always get the best price. We do this by looking for and applying the best voucher codes and deals when you’re at the checkout of your favourite online retailer. This means no more having to leave the checkout to go and find vouchers and deals yourself for the 3,000 (and rising!) retailers we work with.</p>
        <p>The way we make sure you’re getting the best deal is because Pouch is a desktop browser extension which you can download in Chrome Web Store and Firefox Browser Add Ons. Once installed, you’ll see the Pouch icon in your browser’s toolbar near the top of your screen.</p>
        <p>When you visit one of the 3,000+ UK retailers where we have negotiated exclusive deals for you, the icon will light up and if you click it, it will tell you how many deals and vouchers are available with that retailer. Then you can choose to apply the vouchers in the checkout, and for some retailers, we apply the code automatically, ensuring you always get the best deal without ever having to leave the checkout (win win!).</p>
      </div>

      <hr>

      <p @click="part = 'exclusive_deals'" class="title is-5 part" id="exclusive-deals">
        Exclusive Deals
      </p>
      <p v-show="part === 'exclusive_deals'">We work with the best retailers in the UK to bring you exclusive deals and offers and then we tell you about them when you visit the retailers website. We always try to negotiate best in market deals, so you can be sure you’re getting the best possible price. If you are shopping with a retailer where we don’t have an offer, you can let us know by contacting us via email or Facebook.</p>

      <hr>

      <p @click="part = 'its_free'" class="title is-5 part" id="its-free">
        It's Free
      </p>
      <p v-show="part === 'its_free'">Pouch is a browser extension which you download via Chrome or Firefox and an icon sits in the browser toolbar at the top of your screen. When you shop with retailers where we have negotiated exclusive offers for you, the icon will light up and will let you know how many vouchers and/or deals we have available with that retailer. Then you can choose to apply the offers once you’re in the checkout and for some retailers, we can even do this automatically for you, which means you’ll always get the best deal without ever having to leave the checkout.. And all of this is free, you don’t pay Pouch a thing!</p>

      <hr>

      <p @click="part = 'data_protection'" class="title is-5 part" id="data-protection">
        Our Privacy Policy
      </p>
      <div v-show="part === 'data_protection'">
        <p>At Pouch we take data protection very seriously. We’ve even written a <a href="https://joinpouch.com/blog/how-pouch-looks-after-your-data" target="_blank">blog article</a> on the topic here:</p>
        <p>To summarise, we do not capture any personal data and all data is anonymised (basically, we’ve got no way of knowing who you are or what you’re doing online). When you install Pouch, you will see a message from Chrome and Firefox telling you about the permissions we need to access, but its only so we can pop up in the right places to tell you about our exclusive deals. Whilst these messages from Chrome and Firefox can appear scary, we only track which e-commerce sites you visit.</p>
        <p>We also do not sell your data, and are fully GDPR compliant. Prior to our acquisition by a German company (Germany has the strictest rules around data protection in the world!), we went through stringent due diligence process that ensures our data protection policies are in line with GDPR.</p>
        <p>For our full Privacy Policy, please click <a href="https://joinpouch.com/privacy-policy" target="_blank">here</a>.</p>
      </div>

      <br>

      <p class="has-text-right">
        <add-to-browser
          bold
          v-only-extension-fit
          @click="$emit('close')"
          position="PropositionModalEN"
        >
          <span v-t="`buttons.add-pouch`"></span>
        </add-to-browser>
      </p>
    </div>
  </p-modal>
</template>

<script>
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  props: ['active', 'part'],

  components: { AddToBrowser },

  data () {
    return {
      activePart: this.part
    }
  },

  watch: {
    part (value) {
      this.activePart = value
    }
  }
}
</script>
